import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  COLOR, MAX_TABLET_WIDTH,
} from '../../constants';
import SymbolsCombined from './SymbolsCombined';
import { INDEX_COLUMN_DIFFICULTY, INDEX_COLUMN_EDIT, INDEX_COLUMN_NOTE } from './Symbol';

const Container = styled.div`
  display: flex;
  margin-bottom: 1rem;
  min-height: 5rem;
  cursor: pointer;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    flex-direction: column;
  }
`;

const Box = styled.div`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 50%;
`

const SymbolsContainer = styled.div`
  flex: 0 0 2rem;
  padding-right: 0.5rem;
  background: #444;
  padding-left: 0.5rem;
  border: 1px solid white;
  border-right: none;
`
const QuestionContainer = styled.div`
  flex: 0 0 calc(100% - 2rem);
`

const Question = styled.div`
  padding: 1rem;
  padding-bottom: 2.5rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;
  position: relative;
  min-height: 9rem;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  position: relative;
`;

type RightProps = {
  show: boolean;
}
const Answer = styled.div<RightProps>`
  padding: 1rem;
  border: 1px solid white;
  width: calc(100% - 1rem);
  white-space: pre-wrap;

  @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
    width: 100%;
  }

  visibility: ${props => props.show ? 'visible' : 'hidden' };
  background-color: ${props => props.show ? '#303030' : '#121212'};
  position: relative;
  padding-bottom: 2rem;
`;


const Tag = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  background-color: #777;
  padding: 2px 6px;
  margin-right: 0.5rem;
  border-radius: 4px;
`;
const Tags = styled.div`
  display: inline-flex;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1rem;
`;

const Count = styled.div`
  position: absolute;
  right: 1rem;
  border: 1px solid white;
  padding: 2px 6px;
  border-radius: 4px;
`;
const Reference = styled.div`
  margin-top: 1rem;
`

const getShow = (clicks, show) => {
  if (clicks === 0) {
    return false;
  }
  return show;
}

type CsvRowProps = {
  data: { cells: any[], dataRowNumber: number };
  clicks: number;
  range: string;

  incrementClicks: () => void;
  decrementClicks: () => void;
  incrementGoogleCounter: (dataRowNumber: number) => void;
  updateGoogleSheet: (data: {[key: string]: string}) => void;
}

const hasTags = (data) => {
  const potentialTagCell = data[2];
  if (!potentialTagCell) {
    return false;
  }
  if (Array.isArray(potentialTagCell)) {
    return true
  }
  return false
}
export function CsvRow (props: CsvRowProps) {
  const {
    data,
    clicks,
    range,

    incrementClicks,
    decrementClicks,
    incrementGoogleCounter,
    updateGoogleSheet,
  } = props;
  const [show, setShow] = useState(false);

  const display = getShow(clicks, show);

  const dataRowNumber = data.dataRowNumber;

  const handleClick = (e) => {
    if (!show) {
      incrementGoogleCounter(dataRowNumber);
    }

    setShow(!show);
    if (display) {
      decrementClicks();
    } else {
      incrementClicks();
    }
  }

  const handleSymbolEdit = (data) => {
    updateGoogleSheet(data);
  }

  const symbolData = {
    difficulty: data.cells[INDEX_COLUMN_DIFFICULTY],
    edit: data.cells[INDEX_COLUMN_EDIT],
    note: data.cells[INDEX_COLUMN_NOTE],
  }

  return (
    <Container>
      <Left>
        <SymbolsContainer>
          <SymbolsCombined
            data={symbolData}
            handleSymbolEdit={handleSymbolEdit}
          />
        </SymbolsContainer>

        <QuestionContainer>
          <Question onClick={handleClick}>
            {data.cells[0]}

            {data.cells[10] && (
              <Count>
                {data.cells[10]}
              </Count>
            )}
          </Question>
        </QuestionContainer>

      </Left>

      <Answer show={display}>
        <div>
          {data.cells[1]}
        </div>
        <Reference>
          <a href={data.cells[2]}>{data.cells[2]}</a>
        </Reference>

      </Answer>

    </Container>
  )
}
