import React, { useState, useEffect } from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import _ from 'lodash';
import { Button, LinearProgress } from '@material-ui/core';
import styled from '@emotion/styled';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import qs from 'qs';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';

import { COLOR, MAX_TABLET_WIDTH } from '../../constants';
import { getTheme } from '../../utility/theme';
import Layout from '../Layout';
import { CsvRow } from './RowMCQ';
import { Container, CounterContainer, Inner } from './PageCsv';
import SEO from '../seo';
import { COLUMN_DIFFICULTY, COLUMN_EDIT, INDEX_COLUMN_DIFFICULTY, INDEX_COLUMN_EDIT, INDEX_COLUMN_NOTE } from './Symbol';

const muiTheme = (userTheme: string) =>
  createMuiTheme({
    palette: {
      type: userTheme === 'dark' ? 'dark' : 'light',
      primary: {
        main: COLOR.PRIMARY,
      },
      text: {
        primary: userTheme === 'dark' ? '#ffffff' : '#333333',
      },
    },
  });

type TagProps = {
  selected: boolean;
}
const Pill = styled.div<TagProps>`
  cursor: pointer;
  font-size: 0.8rem;
  font-style: italic;
  background-color: ${props => props.selected ? '#ff6961' : '#777'};
  padding: 2px 6px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
`;
const Pills = styled.span`
  display: inline-flex;
  padding: 0;
  flex-wrap: wrap;
  border-top: 1px solid white;
  padding-top: 1rem;
`;


const processRow = (row) => {
  const question = row[0];
  const answer = row[1];
  const passRate = row[2];
  const type = row[3];
  const tagColumns = row.slice(4);
  const allTags = tagColumns.map(col => {
    const arr = col.split(', ');
    return arr;
  });
  const tags = _.compact(_.uniq(_.flatten(allTags)));
  return {
    question,
    answer,
    passRate,
    type,
    tags,
  }
};

const getTags = rows => {
  const data = rows.slice(1);
  const tags = data.map(d => {
    return d.system;
  })
  // console.log('tags:', tags);
}

const processRows = (raw) => {
  const processed = raw.map((d, i) => {
    return {
      cells: d,
      dataRowNumber: i + 1,
      countViewed: d[10] || 0,
    }
  })

  return {
    rows: processed,
  }
}
const Spacer = styled.div`
  margin-bottom: 2rem;
`;

const SPREADSHEET = '12bVHSZT2Qc5Lslf5RXL2xealjZTHN3oQ1r4IeEsku5s';
const SAQ_SHEETS = [
  '2021.1',
  '2020.2',
];
const RANGES = SAQ_SHEETS.map(s => 'ranges=' + s + '!A2:I').join('&');
const KEY = process.env.GATSBY_GOOGLE_SHEETS_KEY;

function handleKeyPress() {
  useEffect(() => {
  }, []);
}
const Buttons = styled.div`
  button {
    margin-right: 1rem;
    @media screen and (max-width: ${MAX_TABLET_WIDTH}) {
      margin-right: 0.5rem;
      min-width: 3rem;
    }
  }
`;

type ROW = {
  cells: string[];
  dataRowNumber: number;
  countViewed: number;
}
const ROW_EMPTY = {
  cells: [''],
  dataRowNumber: 0,
  countViewed: 0,
}

const PillHeading = styled.span`
  margin-right: 1rem;
`

const getShuffled = (rows: ROW[]) => {
  const result = _.sortBy(rows, () => Math.random() - 0.5);
  return result;
}
const ORDERED_LIST_DIFFICULTY = ['Hard', 'Regular', 'Easy'];
const ORDERED_LIST_EDIT = ['Todo', 'Done']

const sortDifficulty = (rows) => function(obj) {
  const foundIndex = ORDERED_LIST_DIFFICULTY.indexOf(obj.cells[INDEX_COLUMN_DIFFICULTY]);
  return foundIndex === -1 ? rows.length : foundIndex;
};
const sortEdit = (rows) => function(obj) {
  const foundIndex = ORDERED_LIST_EDIT.indexOf(obj.cells[INDEX_COLUMN_EDIT]);
  return foundIndex === -1 ? rows.length : foundIndex;
};
export const getSortedByDifficulty = (rows: ROW[]): ROW[] => {
  const result = _.sortBy(
    rows,
    sortDifficulty(rows)  ,
    getShuffled
  )
  return result as any;
}
export const getSortedByEdit = (rows: ROW[]): ROW[] => {
  const result = _.sortBy(
    rows,
    sortEdit(rows),
    getShuffled
  )
  return result as any;
}
const getSortedByNew = (rows: ROW[]): ROW[] => {
  const shuffle = () => Math.random() - 0.5;
  const sorted = _.sortBy(rows, function (x) {
    return Number(x.countViewed);
  }, shuffle)
  return sorted;
}
const getUpdatedLocalRows = (rows: ROW[], dataRowNumber: number, data): ROW[] => {
  const row = rows.find(r => r.dataRowNumber === dataRowNumber);
  const updatedCells = row!.cells;
  updatedCells[INDEX_COLUMN_DIFFICULTY] = data.difficulty;
  updatedCells[INDEX_COLUMN_EDIT] = data.edit;
  updatedCells[INDEX_COLUMN_NOTE] = data.note;

  const updatedRow = {
    ...row,
    cells: updatedCells,
  }
  const maybe = rows[dataRowNumber - 1];
  const updatedRows = [
    ...rows.slice(0, dataRowNumber - 1),
    updatedRow,
    ...rows.slice(dataRowNumber),
  ]
  return updatedRows as ROW[];
}

const Page = (props) => {
  const userTheme = getTheme();
  const theme = muiTheme(userTheme);

  const { title, range, sheetId, spreadsheetId } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [initialRows, setInitialRows] = useState([ROW_EMPTY]);
  const [displayRows, setDisplayRows] = useState([ROW_EMPTY]);
  const [clicks, setClicks] = useState(0);
  const [mode, setMode] = useState('new')
  const [showAlert, setShowAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('');

  const [allSystems, setAllSystems] = useState([]);
  const [currentSystem, setCurrentSystem] = useState('All');

  const [allTags, setAllTags] = useState([]);
  const [currentTag, setCurrentTag] = useState('All');

  const incrementClicks = () => {
    setClicks(clicks + 1);
  }
  const decrementClicks = () => {
    setClicks(clicks - 1);
  }
  const handleUpdateGoogleSheet = (dataRowNumber) => async (data) => {
    const updatedLocal = getUpdatedLocalRows(displayRows, dataRowNumber, data);
    setDisplayRows(updatedLocal);
    const googleSheetRowNumber = dataRowNumber + 1;
    const dataStr = qs.stringify(data);
    const URL = `https://medicalgems.com/update?spreadsheetId=${spreadsheetId}&sheet=${sheetId}&row=${googleSheetRowNumber}&${dataStr}`;
    if (process.env.NODE_ENV !== 'production') {
      return;
    }
    const result = await axios.get(URL).then(() => {
      setShowAlert(true);
      setMsgAlert('Updated successfully');
      setTypeAlert('success')
    }).catch(() => {
      setShowAlert(true);
      setMsgAlert('Error with update');
      setTypeAlert('error')
    })
  }

  const incrementGoogleCounter = async (dataRowNumber) => {
    const sheetRowNumber = dataRowNumber + 1;


    const URL = `https://medicalgems.com/increment?spreadsheetId=${spreadsheetId}&sheet=${sheetId}&row=${sheetRowNumber}`;
    // won't be able to test this locally because we have medicalgems.com and localhost

    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    const result = await axios.get(URL, {
      headers: {
        // 'Access-Control-Allow-Origin' : '*',
        // crossdomain: true,
        // 'Access-Control-Allow-Credentials':true,
        // mode: 'no-cors',
      }
    });
    // console.log('result:', result);
  }

  useEffect(() => {
    async function getData() {
      const KEY = process.env.GATSBY_GOOGLE_SHEETS_KEY;
      const { spreadsheetId, range } = props;

      const URL = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${KEY}`;
      const result = await axios.get(URL).catch(console.log);
      const resultValues = result && result.data.values;

      const processed = processRows(resultValues);
      setInitialRows(processed.rows);
      const sorted = getSortedByNew(processed.rows);
      setDisplayRows(sorted);

      setIsLoading(false);
    }
    getData();

    function onKeyPress(e) {
      if (e.key === 'e') {

      }
    }
    window.addEventListener('keydown', onKeyPress);
    return () => window.removeEventListener('keydown', onKeyPress);
  },[]);

  const shuffle = () => {
    const shuffled = _.sortBy(displayRows, () => Math.random() - 0.5);
    setDisplayRows(shuffled);
    setClicks(0);
    setMode('shuffle')
  };
  const sortByNew = () => {
    const sorted = getSortedByNew(initialRows);
    setDisplayRows(sorted);
    setDisplayRows(sorted);
    setClicks(0);
    setMode('new')
  }
  const sortByNumber = () => {
    setDisplayRows(initialRows);
    setClicks(0);
    setMode('123')
  }
  const sortByDifficulty = () => {
    const sorted = getSortedByDifficulty(initialRows);
    setDisplayRows(sorted);
    setMode('difficulty')
    setClicks(0);
  }
  const sortByEdit = () => {
    const sorted = getSortedByEdit(initialRows);
    setDisplayRows(sorted);
    setMode('edit')
    setClicks(0);
  }

  const counter = displayRows ? `${clicks} / ${displayRows.length}` : '...';



  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title={title} />
        <h1>{title}</h1>
        <Container>
          <Inner>
          <CounterContainer>
            {counter}
          </CounterContainer>
          </Inner>
        </Container>

        <Spacer />

        <Snackbar
          open={showAlert}
          autoHideDuration={2000}
          onClose={() => {
            setShowAlert(false)
          }}
          message={msgAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert variant="filled" severity={typeAlert as AlertColor}>
            {msgAlert}
          </Alert>
        </Snackbar>

        <Buttons>
          <Button type="button" color="primary" variant={mode === 'shuffle' ? 'contained' : 'outlined'} onClick={shuffle}>
              <AutorenewIcon />
          </Button>
          <Button type="button" color="primary" variant={mode === 'new' ? 'contained' : 'outlined'} onClick={sortByNew}>
              New
          </Button>
          <Button type="button" color="primary" variant={mode === '123' ? 'contained' : 'outlined'} onClick={sortByNumber}>
              123
          </Button>
          <Button type="button" color="primary" variant={mode === 'difficulty' ? 'contained' : 'outlined'} onClick={sortByDifficulty}>
              ⬛
          </Button>
          <Button type="button" color="primary" variant={mode === 'edit' ? 'contained' : 'outlined'} onClick={sortByEdit}>
          📝
          </Button>
        </Buttons>

        <Spacer />


        <Spacer />

        {isLoading && <LinearProgress />}

        {!isLoading && displayRows.map((d, i) => {
          const key = `${d.dataRowNumber}`;
          return (
            <CsvRow
              key={key}
              data={d}
              clicks={clicks}
              range={range}

              incrementClicks={incrementClicks}
              decrementClicks={decrementClicks}
              incrementGoogleCounter={incrementGoogleCounter}
              updateGoogleSheet={handleUpdateGoogleSheet(d.dataRowNumber)}
            />
           )
        })}

      </Layout>
    </ThemeProvider>
  )
}

export default Page;
